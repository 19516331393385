import React from 'react'
import PropTypes from 'prop-types'

import PreviewCompatibleImage from '../PreviewCompatibleImage';
import { HTMLContent } from '../Content'
import FormattedDate from '../FormattedDate'
import styles from './styles.module.scss';
import twitter from '../../img/social/twitter.svg';
import facebook from '../../img/social/facebook.svg';

export const BlogPost = ({
  content,
  title,
  cover,
  publishedAt
}) => (
  <div className={styles.post}>
    <header
      className={styles.post__header}
    >
      <PreviewCompatibleImage imageInfo={{
        image: cover
      }} />
      <h1
        className={styles.post__headerTitle}
      >
        {title}
      </h1>
    </header>
    <article
      className={styles.post__content}
    >
      <section
        className={styles.post__date}
      >
        <FormattedDate
          date={publishedAt}
          format="'le' dd MMMM yyyy 'à' HH:MM"
        />
      </section>
      <section>
        <HTMLContent content={content} />
      </section>
    </article>
    <aside>
      <section
        className={styles.post__share}>
          <h4
            className={styles.post__shareTitle}
          >
            Partagez cet article sur vos réseaux sociaux !
          </h4>
          <a
            href={`https://twitter.com/intent/tweet?text=${title}&amp;url=${global.location && global.location.href}`}
            onClick={e => {
              e.preventDefault();
              global.open(e.currentTarget.getAttribute('href'), 'twitter-share', 'width=550,height=235')
            }}
          >
            <img
              className={styles.post__shareIcon}
              src={twitter}
              alt="twitter"
            />
          </a>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${global.location && global.location.href}`}
            onClick={e => {
              e.preventDefault();
              global.open(e.currentTarget.getAttribute('href'), 'facebook-share', 'width=580,height=296')
            }}
          >
            <img
              className={styles.post__shareIcon}
              src={facebook}
              alt="facebook"
            />
        </a>
      </section>
    </aside>
  </div>
)


BlogPost.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}
export default BlogPost
